<template>
  <v-footer
      light
      padless
      style="background-color: #455A64 !important;"
  >
    <v-card
        flat
        tile
        style="background-image: url('https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/2021-11-11%2015.50.28.jpg?alt=media&token=3f5165ab-56cb-4449-b75c-c971da6c4cbc');background-size: cover; width: 100%"

        class="blue-grey darken-2 white--text text-center"
    >
      <v-sheet
          :color="colors"
          height="103%"
      >
        <v-card-text>
          <v-btn
              v-for="social in socialList"
              :key="social.icon"
              class="mx-4 white--text"
              icon
              :href="social.link"
              target="_blank"
          >
            <v-icon size="24px">
              {{ social.icon }}
            </v-icon>
          </v-btn>
        </v-card-text>
        <v-card-text class="white--text pt-0">
          <v-row :style="$vuetify.breakpoint.mobile ? '' : 'padding-left:10%;padding-right:10%'">
            <v-col cols="12" md="4" sm="4">
              <img src="@/assets/logo.png" :height="321*0.15" :width="263*0.15"/>
              <h1 style="padding-top: 15px;font-family: Roboto">RPITSB</h1>
              <br>
              <p style="text-align: left" v-html="getTranslate(dataFooter.addressFooter)"></p>
              <v-list-item>
                <v-list-item-icon>
                  <v-btn small fab color="success">
                    <v-icon>mdi-phone</v-icon>
                  </v-btn>
                </v-list-item-icon>
                <v-list-item-content style="padding-top: 0px !important;padding-bottom: 0px !important;">
                  <v-list-item-title style="color: white;text-align: start !important;"
                                     v-html="dataFooter.phoneNumberFooter"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-btn small fab color="primary">
                    <v-icon>mdi-email</v-icon>
                  </v-btn>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title style="color: white;text-align: start"
                                     v-html="dataFooter.emailFooter"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" md="4" sm="4">
              <h1 style="padding-top: 15px;font-family: Roboto">{{ $t('schedule') }}</h1><br><br>
              <h2 style="text-align: start;color: lightgreen">{{ $t('mondayToFriday') }}</h2>
              <v-list-item v-for="(d) in scheduleWeekday" :key="d.name">
                <v-list-item-content style="color: white">
                  {{ $t(d.time) }}
                </v-list-item-content>
                <v-list-item-content
                    style="padding-top: 0px !important;padding-bottom: 0px !important;min-width: 250px">
                  <v-list-item-title style="color: white;text-align: start !important;"> : {{
                      d.value
                    }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <br>

              <h2 style="text-align: start;color: lightgreen">{{ $t('saturdayAndSunday') }}</h2>
              <v-list-item v-for="(d) in scheduleWeekend" :key="d.name">
                <v-list-item-content style="color: white">
                  {{ $t(d.time) }}
                </v-list-item-content>
                <v-list-item-content
                    style="padding-top: 0px !important;padding-bottom: 0px !important;min-width: 250px">
                  <v-list-item-title style="color: white;text-align: start !important;"> : {{
                      d.value
                    }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <br>
              <h1>{{ $t('scanHereToEnroll') }}</h1>
              <br>
              <img src="@/assets/register.jpg" height="200" width="200"/></v-col>

            <v-col cols="12" md="4" sm="4">
              <div>
                <h1 style="padding-top: 15px;font-family: Roboto">{{ $t('vision') }}</h1>
                <p style="text-align: justify">{{ $t('visionDetail') }}</p>

                <h1 style="padding-top: 15px;font-family: Roboto">{{ $t('mission') }}</h1>
                <v-list subheader three-line style="background-color: transparent">
                  <v-list-item
                      v-for="(mi,i) in missionList"
                      :key="i"
                  >
                    <v-list-item-icon>
                      <v-icon :color="'green'">
                        mdi-star
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content style="color: white;text-align: justify;padding-top: 0px !important;">
                      <v-list-item-subtitle style="color: white;-webkit-line-clamp: 10;">{{ $t(mi.title) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <h1 style="padding-top: 15px;font-family: Roboto">{{ $t('goal') }}</h1>
                <p style="text-align: justify">{{ $t('goalDetail') }}</p>


              </div>
            </v-col>
          </v-row>
        </v-card-text>


        <v-divider></v-divider>
      </v-sheet>
    </v-card>


    <v-card-text class="white--text">
      {{ new Date().getFullYear() }} — <strong>
      <v-icon style="color: white">mdi-copyright</v-icon>
      RPITSB</strong>
    </v-card-text>
  </v-footer>
</template>

<script>
import {Constants} from "@/libs/constant";
import MyMixin from "@/mixins/mixin"

export default {
  props: ["dataFooter"],
  mixins: [MyMixin],

  data: () => ({
    socialList: Constants.socialLink,
    phoneNumberList: Constants.phoneNumberList,
    scheduleWeekday: Constants.scheduleWeekday,
    scheduleWeekend: Constants.scheduleWeekend,
    email: "thoeunchhim@yahoo.com",
    missionList: Constants.missionList,
    colors: "rgb(14, 64, 106,0.7)",
  }),
}
</script>