<template>
  <v-carousel
      cycle
      height="400"
      hide-delimiter-background
      :show-arrows="false"
      hide-delimiters
  >
    <template v-slot:prev="{ on, attrs }">
      <v-btn
          color="success"
          v-bind="attrs"
          v-on="on"
      >Previous slide
      </v-btn>
    </template>
    <template v-slot:next="{ on, attrs }">
      <v-btn
          color="info"
          v-bind="attrs"
          v-on="on"
      >Next slide
      </v-btn>
    </template>

    <v-carousel-item
        v-for="(slide, i) in slides"
        :key="i"
        src="@/assets/banner.jpeg"
    >

      <v-sheet
          :color="colors[i]"
          height="103%"
      >
        <v-row
            class="fill-height"
            align="center"
            justify="center"
            style="margin-left: 10px;margin-right: 10px;background-color: transparent"
        >
          <tab-list style="top:20px"></tab-list>

          <img src="../../assets/logo.png" :height="321*0.50" :width="263*0.50"/>

          <img src="../../assets/rice.png" :height="733*0.2" :width="920*0.2"
               style="position: absolute; left: 40px;top:50px" class="animation-round"/>

          <div :class="$vuetify.breakpoint.mobile ? 'text-h5' : 'text-h4'"
               style="font-family: 'fantasy';font-weight: bold">
            <p style="font-family: 'Khmer OS Muol Light';font-weight: normal;color: white"
               class="animate__animated animate__flipInY animate__duration-5s">{{ slide.km }}</p>
            <span style="color: white;">{{ slide.en }}</span>
          </div>
        </v-row>
      </v-sheet>
    </v-carousel-item>
  </v-carousel>
</template>
<script>
import {Constants} from "@/libs/constant";
import TabList from "@/components/tabList";

export default {
  components: {TabList},
  data() {
    return {
      colors: [
         // 'rgb(79, 195, 247,0.8)',
         'rgb(14, 64, 106,0.85)',
        // 'rgb(231, 100, 38,0.9)',
        // 'rgb(231, 248, 238,0.9)',
        '#e7f8ee',
        'green lighten-4',
        'blue lighten-5',
        'light-blue lighten-4',
        'blue lighten-4'
      ],
      slides: [
        {
          km: 'វិទ្យាស្ថានពហុបច្ចេកទេសភូមិភាគតេជោសែនបាត់ដំបង',
          en: "Regional Polytechnic Institute Techo Sen Battambang"
        },
        // { km:'វិទ្យាស្ថានពហុបច្ចេកទេសភូមិភាគតេជោសែនបាត់ដំបង',en:"Regional Polytechnic Institute Techo Sen Battambang"},
        // { km:'វិទ្យាស្ថានពហុបច្ចេកទេសភូមិភាគតេជោសែនបាត់ដំបង',en:"Regional Polytechnic Institute Techo Sen Battambang"},
        // { km:'វិទ្យាស្ថានពហុបច្ចេកទេសភូមិភាគតេជោសែនបាត់ដំបង',en:"Regional Polytechnic Institute Techo Sen Battambang"},
        // { km:'វិទ្យាស្ថានពហុបច្ចេកទេសភូមិភាគតេជោសែនបាត់ដំបង',en:"Regional Polytechnic Institute Techo Sen Battambang"},
      ],
      tabList: Constants.tabList,


    }
  },
}
</script>

<style lang="css" scoped>

.animation-round {
  animation: mymove 5s infinite;
  transition: all 2s ease;
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-delay: 2s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes mymove {
  0% {
    left: 5px;
    top: 5px;
  }
  25% {
    left: 10px;
    top: 10px;
  }
  50% {
    left: 20px;
    top: 15px;
  }
  75% {
    left: 10px;
    top: 10px;
  }
  100% {
    left: 5px;
    top: 5px;
  }
}
</style>