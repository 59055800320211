import Vue from 'vue'
import Router from 'vue-router'
//import Home from '../views/home.vue'

Vue.use(Router)

const routes = [
    // {
    //   path: '/',
    //   name: 'Home',
    //   component: Home
    // },
    {
        path: '/',
        name: 'Home',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "home" */ '../views/home.vue')
    },
    {
        path: '/about',
        name: 'About',
        component: () => import(/* webpackChunkName: "about" */ '../views/about.vue')
    },
    {
        path: '/certificate/:id/show',
        name: 'Certificate',
        component: () => import(/* webpackChunkName: "about" */ '../views/certificate.vue')
    },
    {
        path: '/structure-leader',
        name: 'StructureLeader',
        component: () => import(/* webpackChunkName: "StructureLeader" */ '../components/structure/leader')
    },
    {
        path: '/structure-apo',
        name: 'StructureAPO',
        component: () => import(/* webpackChunkName: "StructureAPO" */ '../components/structure/administrationOffice')
    },
    {
        path: '/structure-fco',
        name: 'StructureFCO',
        component: () => import(/* webpackChunkName: "StructureFCO" */ '../components/structure/accountingOffice')
    },
    {
        path: '/structure-aao',
        name: 'StructureAAO',
        component: () => import(/* webpackChunkName: "StructureAAO" */ '../components/structure/academicOffice')
    },
    {
        path: '/structure-tcdo',
        name: 'StructureTCDO',
        component: () => import(/* webpackChunkName: "StructureTCDO" */ '../components/structure/tcdoOffice')
    },
    {
        path: '/structure-department',
        name: 'StructureDepartment',
        component: () => import(/* webpackChunkName: "StructureDepartment" */ '../views/structure_department')
    },
    {
        path: '/rector-welcome',
        name: 'RectorWelcome',
        component: () => import(/* webpackChunkName: "RectorWelcome" */ '../views/rectorWelcome')
    },
    {
        path: '/curriculum-bachelor',
        name: 'CurriculumBachelor',
        component: () => import(/* webpackChunkName: "Curriculum" */ '../components/curriculum/bachelor')
    },
    {
        path: '/curriculum-certificate',
        name: 'CurriculumCertificate',
        component: () => import(/* webpackChunkName: "Curriculum" */ '../components/curriculum/certificate')
    },
    {
        path: '/curriculum-high-diploma',
        name: 'CurriculumHighDiploma',
        component: () => import(/* webpackChunkName: "Curriculum" */ '../components/curriculum/high_diploma')
    },
    {
        path: '/curriculum-short-course',
        name: 'CurriculumShortCourse',
        component: () => import(/* webpackChunkName: "Curriculum" */ '../components/curriculum/short_course')
    },
    {
        path: '/curriculum-technical',
        name: 'CurriculumTechnical',
        component: () => import(/* webpackChunkName: "Curriculum" */ '../components/curriculum/technical')
    },
    {
        path: '/national-cooperation',
        name: 'NationalCooperation',
        component: () => import(/* webpackChunkName: "Cooperation" */ '../views/national_cooperation')
    },
    {
        path: '/international-cooperation',
        name: 'InternationalCooperation',
        component: () => import(/* webpackChunkName: "Cooperation" */ '../views/international_cooperation')
    },
    {
        path: '/news-events-detail',
        name: 'NewsEventsDetail',
        component: () => import(/* webpackChunkName: "Cooperation" */ '../views/new_and_event_detail')
    },
    {
        path: '/news-events',
        name: 'NewsEvents',
        component: () => import(/* webpackChunkName: "Cooperation" */ '../views/new_and_event')
    },
    {
        path: '/media',
        name: 'Media',
        component: () => import(/* webpackChunkName: "Cooperation" */ '../views/media')
    }
]

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                selector: to.hash,
                behavior: 'smooth',
            }
        } else if (savedPosition) {
            return savedPosition
        } else {
            return {x: 0, y: 0}
        }


    }
})

export default router

