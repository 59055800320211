
let SocialLink=[
    {icon:"mdi-facebook",link:"https://www.facebook.com/rtcbattambang"},
    {icon:"mdi-twitter",link:"https://www.facebook.com/rtcbattambang"},
    {icon:"mdi-linkedin",link:"https://www.facebook.com/rtcbattambang"},
    {icon:"mdi-instagram",link:"https://www.facebook.com/rtcbattambang"},
];

let TabList=[
    {name:"home",to:"/"},
    {name:"curriculum",to:"",isHasSub:true,
        subMenu:[
            {name:"bachelor",to:"/curriculum-bachelor"},
            {name:"highDiploma",to:"/curriculum-high-diploma"},
            {name:"technicalAndVocationalCertificate",to:"/curriculum-technical"},
            {name:"certificate",to:"/curriculum-certificate"},
            {name:"shortCourse",to:"/curriculum-short-course"}
        ]
    },
    {name:"structure",to:"",isHasSub:true,
        subMenu:[
            {name:"rpitsbLeader",to:"/structure-leader"},
            {name:"department",to:"/structure-department"},
            {name:"academicOffice",to:"/structure-aao"},
            {name:"administrationOffice",to:"/structure-apo"},
            {name:"accountingOffice",to:"/structure-fco"},
            {name:"technicalOffice",to:"/structure-tcdo"},
        ]},
    {name:"cooperation",to:"",isHasSub:true,
        subMenu:[
            {name:"nationalCooperation",to:"/national-cooperation"},
            {name:"internationalCooperation",to:"/international-cooperation"},
        ]},
    {name:"newsAndEvents",to:"/news-events"},
    {name:"media",to:"/media"},
    {name:"about",to:"/about"}
]
let LanguageList=[
    {name:"ភាសារខ្មែរ",value:"km"},
    {name:"English",value:"en"},
    {name:"中文",value:"cn"}
]
let MissionList=[
    {title:"mission1"},
    {title:"mission2"},
    {title:"mission3"},
    {title:"mission4"},
    {title:"mission5"}
]
let PhoneNumberList=[
    "010 480 762" ,
    "012 829 077" ,
    "087 985 282",
    "077 420 492"
]

let DepartmentList=[
    {title:"it_department",body:"",color:"#1F7087",url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2FFB_IMG_1606119786228%20-%20Coding%20Toy.jpg?alt=media&token=594f5bc8-8f00-4597-96ac-7db6e9cd3b2b"},
    {title:"electronic_department",body:"",color:"#385F73",url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2Faaaaaaaaaaaaaaaaa%20-%20%E1%9E%8F%E1%9E%BB%E1%9E%9B%E1%9E%B6%20%E1%9E%88%E1%9E%BA%E1%9E%93-min.png?alt=media&token=80c46684-435a-4309-aa96-8dc95b34ec57"},
    {title:"electricity_department",body:"",color:"#8A348A",url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2F8%20-%20bev%20chanthorn.jpg?alt=media&token=b6267c77-4373-4634-a3c1-9f14a73962d9"},
    {title:"mechanic_department",body:"",color:"#B42B59",url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2FIMG20210519193411%20-%20SR%20electricity%20Electrical%20Engineering%20Technology-min.jpg?alt=media&token=d886637e-69a1-4502-a967-060cd363484e"},
    {title:"agriculture_and_food_processing_department",body:"",color:"#3D6BE7",url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2F0E41FF81-BFC5-466E-AF69-C180435BB449%20-%20kung%20menghak-min.jpeg?alt=media&token=cbe94925-f703-4c89-a54e-1ae727335f67"},
    {title:"foreign_language_department",body:"",color:"#952175",url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2FB612_20210901_153603_472%20-%20SENGHEANG%20YIN-min.jpg?alt=media&token=fdc4f020-6b9e-42a3-bf23-689c892531cd"},

]
let ScheduleWeekday=[
    {time:"morning",value:"07:30 am - 11:30 pm"},
    {time:"afternoon",value:"01:00 pm - 05:00 pm"},
    {time:"evening",value:"05:30 pm - 08:30 pm"},
]
let ScheduleWeekend=[
    {time:"morning",value:"08:00 am - 12:00 pm"},
    {time:"afternoon",value:"01:00 pm - 05:00 pm"},
]
export const Constants={
    socialLink:SocialLink,
    languageList:LanguageList,
    tabList:TabList,
    departmentList:DepartmentList,
    scheduleWeekday:ScheduleWeekday,
    scheduleWeekend:ScheduleWeekend,
    missionList:MissionList,
    phoneNumberList:PhoneNumberList,
    //baseURL:"http://localhost:3000/api/v1/",
    baseURL:"http://167.71.204.64:8919/api/v1/",
    //baseURLStudent:"http://167.71.204.64:8919/api/v1/",
    baseURLStudent:"http://128.199.233.175:2525/api/v1/",
    token:"narongwebsite",
    branchId:"3MDirTEg4uyhg4YTi",
    //branchId:"wrdmyzBcK8LMDg3mb",
    api:{
        getHome:"home/fetch",
        getAbout:"about/fetch",
        getContact:"contact/fetch",
        getProduct:"product/fetch",
        getNewsAndEvent:"newsAndEvents/fetch",
        getMedia:"media/fetch",
        getClient:"client/fetch",
        getOrganization:"organization/fetch",
        getProductionLine:"productionLine/fetch",
        getHeaderFooter:"headerFooter/fetch",
        getProductReport:"productReport/fetch",
        studentByRegisterId:"student/byReId",
    },

}