<template>
    <v-tabs style="position: absolute;left: 15%;width: 70%;font-family: 'Khmer OS Battambang'" v-if="!$vuetify.breakpoint.mobile">
      <v-tab to="/">
        <div>{{ $t('home') }}</div>
      </v-tab>
      <v-tab to="/rector-welcome">
        <div>{{ $t('rectorWelcome') }}</div>
      </v-tab>

      <v-menu
          open-on-hover
          close-on-click
          offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-tab   v-bind="attrs"
                   v-on="on">
            <div>{{ $t('program') }}</div> <v-icon size="24px">mdi-menu-down</v-icon>
          </v-tab>
        </template>
        <v-list shaped>
        <v-list-item-group
            color="primary"
        >
          <v-list-item
              v-for="(item, i) in curriculumList"
              :key="i"
              :to="item.to"
          >
            <v-list-item-content>
              <v-list-item-title style="text-align: left" v-text="$t(item.name)"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        </v-list>
      </v-menu>

      <v-menu
          open-on-hover
          close-on-click
          offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-tab   v-bind="attrs" to="/structure-leader"
                   v-on="on">
            <div>{{ $t('structure') }}</div> <v-icon size="24px">mdi-menu-down</v-icon>

          </v-tab>
        </template>
        <v-list shaped>
        <v-list-item-group
            color="primary"
        >
          <v-list-item
              v-for="(item, i) in structureList"
              :key="i"
              :to="item.to"
          >
            <v-list-item-content>
              <v-list-item-title style="text-align: left" v-text="$t(item.name)"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        </v-list>
      </v-menu>
      <v-menu
          open-on-hover
          close-on-click
          offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-tab   v-bind="attrs"
                   v-on="on">
            <div>{{ $t('cooperation') }}</div> <v-icon size="24px">mdi-menu-down</v-icon>
          </v-tab>
        </template>
        <v-list shaped>
        <v-list-item-group
            color="primary"
        >
          <v-list-item
              v-for="(item, i) in cooperationList"
              :key="i"
              :to="item.to"
          >
            <v-list-item-content>
              <v-list-item-title style="text-align: left" v-text="$t(item.name)"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        </v-list>
      </v-menu>
      <v-tab to="/news-events">
        <div>{{ $t('newsAndEvents') }}</div>
      </v-tab>
      <v-tab to="/media">
        <div>{{ $t('media') }}</div>
      </v-tab>
      <v-tab to="/about">
        <div>{{ $t('about') }}</div>
      </v-tab>
    </v-tabs>
</template>
<script>
  import {Constants} from "@/libs/constant"
  export default {
    name:"TabList",
    data(){
      return {
        curriculumList: Constants.tabList[1].subMenu,
        structureList: Constants.tabList[2].subMenu,
        cooperationList: Constants.tabList[3].subMenu,
      }
    }
  }
</script>