var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.$vuetify.breakpoint.mobile)?_c('v-tabs',{staticStyle:{"position":"absolute","left":"15%","width":"70%","font-family":"'Khmer OS Battambang'"}},[_c('v-tab',{attrs:{"to":"/"}},[_c('div',[_vm._v(_vm._s(_vm.$t('home')))])]),_c('v-tab',{attrs:{"to":"/rector-welcome"}},[_c('div',[_vm._v(_vm._s(_vm.$t('rectorWelcome')))])]),_c('v-menu',{attrs:{"open-on-hover":"","close-on-click":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-tab',_vm._g(_vm._b({},'v-tab',attrs,false),on),[_c('div',[_vm._v(_vm._s(_vm.$t('program')))]),_vm._v(" "),_c('v-icon',{attrs:{"size":"24px"}},[_vm._v("mdi-menu-down")])],1)]}}],null,false,1647239335)},[_c('v-list',{attrs:{"shaped":""}},[_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.curriculumList),function(item,i){return _c('v-list-item',{key:i,attrs:{"to":item.to}},[_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"text-align":"left"},domProps:{"textContent":_vm._s(_vm.$t(item.name))}})],1)],1)}),1)],1)],1),_c('v-menu',{attrs:{"open-on-hover":"","close-on-click":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-tab',_vm._g(_vm._b({attrs:{"to":"/structure-leader"}},'v-tab',attrs,false),on),[_c('div',[_vm._v(_vm._s(_vm.$t('structure')))]),_vm._v(" "),_c('v-icon',{attrs:{"size":"24px"}},[_vm._v("mdi-menu-down")])],1)]}}],null,false,3218914199)},[_c('v-list',{attrs:{"shaped":""}},[_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.structureList),function(item,i){return _c('v-list-item',{key:i,attrs:{"to":item.to}},[_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"text-align":"left"},domProps:{"textContent":_vm._s(_vm.$t(item.name))}})],1)],1)}),1)],1)],1),_c('v-menu',{attrs:{"open-on-hover":"","close-on-click":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-tab',_vm._g(_vm._b({},'v-tab',attrs,false),on),[_c('div',[_vm._v(_vm._s(_vm.$t('cooperation')))]),_vm._v(" "),_c('v-icon',{attrs:{"size":"24px"}},[_vm._v("mdi-menu-down")])],1)]}}],null,false,2441237034)},[_c('v-list',{attrs:{"shaped":""}},[_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.cooperationList),function(item,i){return _c('v-list-item',{key:i,attrs:{"to":item.to}},[_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"text-align":"left"},domProps:{"textContent":_vm._s(_vm.$t(item.name))}})],1)],1)}),1)],1)],1),_c('v-tab',{attrs:{"to":"/news-events"}},[_c('div',[_vm._v(_vm._s(_vm.$t('newsAndEvents')))])]),_c('v-tab',{attrs:{"to":"/media"}},[_c('div',[_vm._v(_vm._s(_vm.$t('media')))])]),_c('v-tab',{attrs:{"to":"/about"}},[_c('div',[_vm._v(_vm._s(_vm.$t('about')))])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }